import axios from '../utils/axios';

const getAtestados = async (paciente_id) => {
    let result = (await axios.get('pacientes/atestados', { params: { paciente_id } })).data;
    // console.log('atestados', result);
    return result;
}

const storeAtestados = async (data) => {
    
    let result = await axios.post('pacientes/atestados', data).then(res=>{
       
         return res
     }).catch(err=>{
        console.log('atestados', err);
         throw err
     })
     return result
}

export default {
    getAtestados,
    storeAtestados
}