<template>
  <div class="container-fluid">
    <div class="p-5 text-center" v-if="loading">
      <b-spinner /><br />
      aguarde...
    </div>
    <div class="text-right">
      <b-button variant="primary" @click="novo">
        <i class="fa fa-plus" /> Adicionar Atestado
      </b-button>
    </div>
     <EmptyState v-if="lista.length == 0">
        <h3>
          Nenhuma atestado encontrada!
        </h3>
        <p>
          Clique em
        <b-button class="mx-1" variant="primary" @click="novo">
        <i class="fa fa-plus" /> Adicionar Atestado
      </b-button>
          para adicionar.
        </p>
      </EmptyState>
    <div v-if="!loading" class="listaItens">
      <!-- <paciente-item-card
        v-for="atestado in lista"
        :key="atestado.id"
        tipo="atestado"
        :objeto="atestado"
      /> -->
      <paciente-item-card
        v-for="(atestado, index) in lista"
        @click.native="editar(atestado)"
        :key="`atestado_${atestado.id}_index_${index}`"
        :objeto="atestado"
        :tipo="`Atestado`"
      />
    </div>

    <b-modal
      id="modal-add-atestado"
      hide-footer
      size="lg"
      title="Adicionar / Editar Atestado Médico"
    >
      <paciente-atestado-form
        :paciente="paciente"
        :atestado_edit="atestadoSelecionado"
        @salvou="onSalvarAtestado"
        :horario="horario"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import PacienteAtestadoLib from "../../../libs/PacienteAtestadoLib.js";
import PacienteAtestadoForm from "./PacienteAtestadoForm.vue";
import PacienteItemCard from "./PacienteItemCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";
export default {
  name:"PacienteAtestado",
  components: {
    PacienteAtestadoForm,
    PacienteItemCard,
    EmptyState
  },
  props: {
    paciente: Object,
    horario: Object,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      lista: [],
      atestadoSelecionado: {},
    };
  },

  methods: {
    novo() {
      this.atestadoSelecionado = {};
      this.$bvModal.show("modal-add-atestado");
    },
    async carregar() {
      this.loading = true;
      this.lista = await PacienteAtestadoLib.getAtestados(this.paciente.id);
      this.loading = false;

     // console.log("astes", this.lista);
    },
    async onSalvarAtestado() {
      this.$bvModal.hide("modal-add-atestado");
      await this.carregar();
    },
    editar(atestado) {
      this.atestadoSelecionado = Object.assign({}, atestado);
      this.atestadoSelecionado.periodo_de = moment(
        this.atestadoSelecionado.periodo_de
      ).format("YYYY-MM-DD");
      this.atestadoSelecionado.periodo_ate = moment(
        this.atestadoSelecionado.periodo_ate
      ).format("YYYY-MM-DD");

      this.$bvModal.show("modal-add-atestado");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
