<template>
  <div style="min-height: 500px">
    <div class="row form">
      <div class="col-12 col-md-6">
        <label>Paciente</label>
        <b-input disabled readonly v-model="paciente.razao_social" />
      </div>
      <div class="col-12 col-md-6">
        <label>Médico</label>
        <select-paciente
          :showBotaoEdit="false"
          :showBotaoAdd="false"
          setNullOnEmpty
          :selecionado="atestado.medico_id"
          @paciente="onMedico"
          :disabled="user.isMedico"
          :tipo="['medico']"
        ></select-paciente>
        <!-- <v-select
          :options="listaMedicos"
          v-model="atestado.medico_id"
          :state="validateState('medico_id')"
          :reduce="(med) => med.value"
        /> -->
      </div>
      <div class="col-12 col-md-6 mt-3">
        <label>Periodo</label>
        <div class="row">
          <div class="col-6 pr-1">
            <b-input
              type="date"
              v-model="atestado.periodo_de"
              @blur.native="calcDias()"
            />
          </div>
          <div class="col-6 pl-1">
            <b-input
              type="date"
              v-model="atestado.periodo_ate"
              @blur.native="calcDias()"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <label>Dias</label>
        <b-input
          v-model="atestado.dias"
          @blur.native="calcPeriodo()"
          type="number"
          min="1"
          max="99"
          :state="validateState('dias')"
        /><br />
      </div>
      <div class="col-12 mt-3">
        <label>Observações</label>
        <b-textarea v-model="atestado.observacoes" />
      </div>

      <div class="col-12 mt-5 text-center">
        <label>Conteudo do Atestado</label>
        <div v-html="atestado.conteudo" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right mt-3">
        <hr />
        <b-button variant="primary" @click="salvar"
          ><i class="fa fa-save" /> Salvar</b-button
        >
        <b-button variant="secondary"
          ><i class="fa fa-ban" /> Cancelar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
import EntidadeLib from "../../../libs/EntidadesLib";
import PacienteAtestadoLib from "../../../libs/PacienteAtestadoLib";
import moment from "moment";
import SelectPaciente from "../../common/SelectPaciente.vue";

export default {
  mixins: [validationMixin],

  components: { SelectPaciente },
  props: {
    paciente: Object,
    atestado_edit: Object,
    horario: Object,
  },
  mounted() {
    // this.carregarMedicos();

    if (
      this.user.isMedico &&
      this.user.entidade_id &&
      !this.atestado.medico_id
    ) {
      this.atestado.medico_id = this.user.entidade_id;
    }

    if (!isNaN(this.atestado_edit.id)) {
      this.atestado = Object.assign({}, this.atestado_edit);
    }
  },
  validations: {
    atestado: {
      medico_id: {
        required,
        minValue: minValue(1),
      },
      dias: {
        required,
        minValue: minValue(1),
      },
    },
  },
  data() {
    return {
      listaMedicos: [],
      hoje: moment().format("DD/MM/YYYY"),
      atestado: {
        paciente_id: this.$props.paciente.id,
        medico_id: 0,
        horario_id: this.horario?.id,
        periodo_de: moment().format("YYYY-MM-DD"),
        periodo_ate: moment().format("YYYY-MM-DD"),
        dias: 1,
        observacoes: "",
        conteudo: "",
        id: 0,
      },
      user: this.$store.state.auth.user,
      canCalulate: false,
    };
  },
  watch: {
    atestado: {
      deep: true,
      handler() {
        // if (this.canCalulate) {
        //   this.atestado.dias = moment(this.atestado.periodo_ate).diff(
        //     this.atestado.periodo_de,
        //     "days"
        //   );
        // }
        this.atestado.conteudo = `Atesto para os devidos fins, a pedido que o Sr.(a)
        ${
          this.paciente.razao_social
        }, paciente sob meus cuidados, foi atendido(a)
        no dia ${this.hoje}, necessitando de ${this.atestado.dias} dias de
        afastamento das atividades no periodo de ${moment(
          this.atestado.periodo_de
        ).format("DD/MM/YYYY")} até
        ${moment(this.atestado.periodo_ate).format(
          "DD/MM/YYYYY"
        )}. <br/>${this.atestado.observacoes.replaceAll(
          "\n",
          "<br/>"
        )}<br/>Franca - SP, ${this.hoje}`;
      },
    },
  },
  computed: {
    corpo() {
      return this.atestado.conteudo;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.atestado[name];
      return $dirty ? !$error : null;
    },
    onMedico(medico) {
      console.log(!medico?.id);
      if (!medico?.id) {
        this.atestado.medico_id = null;
        return;
      }

      this.atestado.medico_id = medico.id;
    },
    isRequired(value) {
      return value ? true : "Este campo é obrigatório";
    },
    calcPeriodo() {
      console.log(1);
      this.atestado.periodo_de = moment().format("YYYY-MM-DD");
      this.atestado.periodo_ate = moment()
        .add(this.atestado.dias, "days")
        .format("YYYY-MM-DD");
    },
    calcDias() {
      this.atestado.dias = moment(this.atestado.periodo_ate).diff(
        this.atestado.periodo_de,
        "days"
      );
    },
    canCalc() {
      this.canCalulate = false;
      this.$forceUpdate();
      this.$nextTick();

      this.$nextTick();
      this.$forceUpdate();

      this.canCalulate = true;
    },
    async carregarMedicos() {
      this.listaMedicos = (await EntidadeLib.get("medico")).entidades.map(
        (items) => {
          return { value: items.id, label: items.razao_social };
        }
      );
      // console.log("lista", this.listaMedicos);
    },
    async salvar() {
      this.$v.atestado.$touch();
      if (this.$v.atestado.$anyError) {
        let campos = [];
        for (const key in this.$v.atestado) {
          if (key.indexOf("$") == -1) {
            // console.log("Tá com erro", key, this.$v.atestado[key]);
            if (this.$v.atestado[key] && this.$v.atestado[key].$error) {
              let prettierName = key.replace("_", "").replace("id", "");
              prettierName =
                prettierName.charAt(0).toUpperCase() + prettierName.slice(1);
              campos.push(prettierName);
            }
          }
        }
        this.$swal(
          "Atenção",
          "Verifique todos os campos antes de salvar! Campos inválidos: [" +
            campos +
            "]",
          "error"
        );

        return;
      }

      // if (this.$v.$invalid) {
      //   let campos =  Object.keys(this.$v.atestado).forEach((campo) => {
      //     return {campo, valid:(this.$v.atestado[campo].$error)}
      //   });
      //   console.log(campos);
      //   this.$swal('Atenção','Verifique todos os campos antes de salvar! ' + campos,'error');
      //   return;
      // }else{
      //   console.log('salvar);')
      // }
      try {
        let result = await PacienteAtestadoLib.storeAtestados(this.atestado);

        if (result.data && (result.status == 201 || result.status == 200)) {
          this.showToast("success", "Atestado gerado com sucesso!");
          this.$emit("salvou");
        }
      } catch (error) {
        this.$swal(
          "Erro",
          `Alguma coisa deu errado ao gerar o atestado :/ \n${error.message}`,
          "error"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  label {
    font-weight: 500;
    display: block;
  }
}
</style>
